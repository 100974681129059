<script setup lang="ts">
import { useAuthStore } from "~/store/auth/auth.store";

const props = defineProps<{
  show_account: boolean;
}>();

const emit = defineEmits(["close"]);

const config = useRuntimeConfig();
const authStore = useAuthStore();

function navigateToLogAs() {
  emit("close");
  return navigateTo(`/admin/log-as`);
}
</script>

<template>
  <div
    class="dropdown_account"
    :class="[show_account ? 'flex flex-col' : 'hidden']"
  >
    <button class="disconnect_button text_med_14" @click="authStore.logOut()">
      Se déconnecter
    </button>
    <button
      v-if="authStore.isSimbelAdmin"
      class="text_med_14 disconnect_button"
      @click="navigateToLogAs"
    >
      Log as
    </button>
  </div>
</template>

<style scoped lang="scss">
.dropdown_account {
  @apply absolute justify-center right-0 rounded-[6px] mr-[30px] p-[12px];
  background: var(--white-main);
  width: 180px;
  box-shadow: var(--shadow-hard);
  z-index: 50;
  margin-top: 10px;
}

.dropdown_account button {
  @apply text-center;
  color: var(--text-tertiary-color);

  &:hover {
    color: var(--primary-blue-color);
  }
}
</style>
