<script setup lang="ts">

import { useNuxtApp } from "#app";
import { useI18n } from 'vue-i18n';
import { useAuthStore } from "~/store/auth/auth.store";
import { useUsersApi } from "~/composables/users/useUsersApi";
import { useFeedback } from "~/composables/feedback/useFeedback";

const { $Localize } = useNuxtApp()
const authStore = useAuthStore()
const userApi = useUsersApi()

const toggled = ref(false)
const selected_val = ref("")
const dropdown = ref<HTMLInputElement | null>(null)
const options = ["FR", "EN"]

const options_available = computed(() => {
  return options?.filter((opt) => {
    return selected_val.value !== opt
  })
})


function untoggleDropdown(event: any) {
  if (dropdown.value && !dropdown.value.contains(event.target)) {
    toggled.value = false
  }
}

onMounted(() => {
  selected_val.value = (authStore.user?.language ?? "FR").toUpperCase()
  document.addEventListener("click", untoggleDropdown)
})

onUnmounted(() => {
  document.removeEventListener('click', untoggleDropdown)
})

async function select_opt(opt: any) {
  const feedback = useFeedback();
  selected_val.value = opt;
  toggled.value = false

  // Dirty fix to type Localize variable
  let typedLocalize: any = $Localize;
  typedLocalize.setLanguage(opt.toLowerCase())
  if (authStore.isLoggedIn()) {
    try {
      await userApi.setUserLanguage(opt.toLowerCase())
      if (authStore.user) {
        authStore.user.language = opt.toLowerCase()
      }
    } catch (error) {
      feedback.error(`Une erreur est survenue`, "small");
    }
  }
  /**
   * we do not set i18n local to EN because Localize do the trad
   */
  // locale.value = opt.toLowerCase()
}

</script>


<template>
  <div ref="dropdown" class="small_dropdown__container body_med">
    <div @click="toggled = !toggled" class="small_dropdown__toggle sub" :data-toggled="toggled">
      <p class="small_dropdown__selected">{{ selected_val }}</p>
      <img src="/icons/generic/arrow_down.svg" class="pr-[2px]" alt="">
    </div>
    <div class="dropdown__menu sub" :class="[toggled ? 'block' : 'hidden']">
      <ul @click="select_opt(opt)" v-for="opt in options_available">
        <li class="small_dropdown__option small_dropdown__padding">
          {{ opt }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.small_dropdown__container {
  position: relative;
  cursor: default;

  --tmp: var(--grey-lightest);


  &[data-disabled="false"] {

    cursor: pointer;

    &:hover {
      --tmp: var(--primary-blue-color);
    }

    .sub {
      border-color: var(--tmp);

      &:has(.dropdown__input:focus) {
        --tmp: var(--primary-blue-color);

        +.sub {
          --tmp: var(--primary-blue-color);
        }
      }
    }
  }

  &[data-disabled="true"] {

    .sub {
      border-color: transparent;
      color: var(--text-tertiary-color);
    }

  }
}

.small_dropdown__toggle {
  display: flex;
  align-items: center;
  background: var(--grey-light);
  border: 2px solid var(--grey-lightest);
  border-radius: var(--border-radius);
  color: var(--text-main-color);
  white-space: normal;
  width: 100%;

  &[data-toggled='true'] {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    box-shadow: var(--shadow-soft);
  }
}


.small_dropdown__selected {
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  white-space: nowrap;
  padding: 3px 0 3px 10px;
  overflow-x: hidden;
  width: 100%;

  &[data-searchable='true'] {
    padding-right: 16px;
  }
}

.small_dropdown__padding {
  padding: 3px 10px;
}

.small_dropdown__option {
  display: block;
  clear: both;
  color: var(--text-main-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  overflow-x: hidden;

  &:hover {
    background: var(--grey-lightest);
  }
}
</style>